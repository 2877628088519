type TransferNoteProps = {
  amount: string
  transferContent: string
}

export default function TransferNote({
  amount,
  transferContent,
}: TransferNoteProps) {
  return (
    <p className="text-center text-sm text-red-500">
      <b>*</b>Bạn phải chuyển khoản <b>{amount}</b> với nội dung &#8220;
      <b>{transferContent}</b>&#8221; để hoàn thành đơn hàng.
    </p>
  )
}
