import {QueryCache, QueryClient} from '@tanstack/react-query'
import {toastStore} from 'atoms/toasts'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: process.env.NODE_ENV === 'production',
    },
  },
  queryCache: new QueryCache({
    onError: (error: any) => {
      const {showToast} = toastStore.getState()
      if (error.statusCode >= 500) {
        showToast({
          variant: 'danger',
          title: 'Lỗi',
          message: 'Có gì đó không đúng đã xảy ra',
        })
      } else if (error.statusCode === 429) {
        showToast({
          variant: 'warning',
          title: 'Cảnh báo',
          message: 'Thao tác quá nhanh, vui lòng đợi trong giây lát',
        })
      }
    },
  }),
})

export default queryClient
