type EmailIconProps = {} & JSX.IntrinsicElements['svg']

function EmailIcon(props: EmailIconProps) {
  return (
    <svg
      fill="#fff"
      width={30}
      height={30}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8 6"
    >
      <path d="m0 0h8v6h-8zm.75 .75v4.5h6.5v-4.5zM0 0l4 3 4-3v1l-4 3-4-3z" />
    </svg>
  )
}

export default EmailIcon
