export enum GameType {
  DOTA = 570,
  CSGO = 730,
}

export type ItemCommonProps = {
  color: string
  name: string
  tag: string
}

export type GameItem = {
  image: string
  type_name: any
  hero: string
  type: any
  imageBottomShow: ItemCommonProps
  quality: ItemCommonProps
  pop: {
    bottom: ItemCommonProps[]
    topName: ItemCommonProps
  }
  show_type: string
  local_image: string
  hero_localname: any
  appid: GameType
  name: string
  is_custom: boolean
  name_color: string
  id: number
  state: number
  shortName: string
  value: number
  rarity: ItemCommonProps
  status: {
    redlock: number
    new: number
  }
}
