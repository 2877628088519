import {useMutation, useQueryClient} from '@tanstack/react-query'
import {getClaims, logout, requestClaims} from './api'
import authKeys from './keys'
import {useUpdateAtom} from 'jotai/utils'
import {showToastAtom} from 'atoms/toasts'
import {BaseResponse} from 'data/orders'
import {APIError} from 'lib/api-client'

export function useLogout() {
  const queryClient = useQueryClient()
  return useMutation(logout, {
    onSettled: () => {
      queryClient.invalidateQueries(authKeys.currentUser)
    },
  })
}

export function useRequestClaims() {
  const showToast = useUpdateAtom(showToastAtom)
  return useMutation<BaseResponse<boolean>, APIError>(requestClaims, {
    onSuccess: ({result}) => {
      if (result) {
        showToast({
          variant: 'success',
          title: 'Đặt hàng thành công',
          message: 'Bạn đã đặt hàng thành công',
        })
      } else {
        showToast({
          variant: 'success',
          title: 'Không thể xử lý',
          message: 'Yêu cầu bị từ chối',
        })
      }
      return
    },
    onError: (err, data) => {
      if (err.statusCode === 409) {
        showToast({
          variant: 'danger',
          title: 'Không thể xử lý',
          message: 'Yêu cầu bị từ chối',
        })
      }
      return
    },
  })
}
