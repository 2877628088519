import type {NextPage} from 'next'
import {useMemo} from 'react'
import {useSettingInfo} from 'data/settings'
import SEO from 'components/seo'
import Logo from 'components/logo'
import Spinner from 'components/spinner'
import Box from 'components/box'

const HomePage: NextPage = () => {
  const {isFetching, data: info} = useSettingInfo()
  const vnCurrency = useMemo(
    () =>
      new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
      }),
    []
  )

  if (isFetching) {
    return <Spinner size="lg" center />
  }

  if (!info) return null

  return (
    <div className="px-4 pt-5">
      <SEO title="Trang chủ" />
      <Logo className="mx-auto mb-8 block" width={90} />
      <div className="grid grid-cols-1 gap-x-3 gap-y-5 md:grid-cols-2">
        <Box>
          Có sẵn các loại Steam Wallet $5, $10, code bạn mua sẽ được hiển thị
          trong lịch sử mua hàng. Lưu ý một số store sẽ không nạp được loại code
          này như: Turkey, Argentina, US.
        </Box>
        <Box>
          520CSGO cung cấp các hình thức thanh toán như quét mã QR (MOMO và các
          ngân hàng hỗ trợ thanh toán QR) hoặc qua hình thức chuyển khoản.
        </Box>
        <Box>
          <p>
            Tất cả hình thức thanh toán đều là tự động, value Empire, Etopfun sẽ
            được chuyển vào tài khoản Steam của bạn sau 2-10p kể từ lúc thanh
            toán.
          </p>
          <p>
            Nếu chưa có tài khoản vui lòng đăng ký tại đây{' '}
            <a
              className="text-blue-500 underline"
              href={info.result?.empireRef}
              target="_blank"
              rel="noreferrer"
            >
              ĐẾ CHẾ
            </a>
            ,{' '}
            <a
              className="text-blue-500 underline"
              href={info.result?.csgo500Ref}
              target="_blank"
              rel="noreferrer"
            >
              500
            </a>
            ,{' '}
            <a
              className="text-blue-500 underline"
              href={info.result?.etopRef}
              target="_blank"
              rel="noreferrer"
            >
              ETOP
            </a>
            .
          </p>
        </Box>
        <Box>
          <p>
            Rate hiện tại dưới đây, rate này không cố định có thể thay đổi theo
            ngày hoặc có biến động mạnh.
          </p>
          <div className="ml-5">
            <p>Giá bán của value:</p>
            <ul className="list-inside list-disc pl-4">
              <li>ĐẾ CHẾ: {vnCurrency.format(info.result?.empire)}</li>
              <li>500: {vnCurrency.format(info.result?.csgo500)}</li>
              <li>
                Etop: {vnCurrency.format(info.result?.dota)} (DOTA),{' '}
                {vnCurrency.format(info.result?.csgo)} (CSGO)
              </li>
            </ul>
          </div>
          <div className="ml-5">
            <p>Giá thu của value:</p>
            <ul className="list-inside list-disc pl-4">
              <li>ĐẾ CHẾ: {vnCurrency.format(info.result?.receiveEmpire)}</li>
              <li>500: {vnCurrency.format(info.result?.receiveCsgo500)}</li>
              <li>Etop: {vnCurrency.format(info.result?.receiveEtop)}</li>
            </ul>
          </div>
        </Box>
      </div>
      <div className="mt-5 text-center">
        Nếu có bất kỳ vấn đề gì có thể liên hệ qua{' '}
        <a
          className="text-blue-600 underline"
          href="https://www.facebook.com/520csgo/"
          target="_blank"
          rel="noreferrer"
        >
          Facebook Fanpage
        </a>{' '}
        hoặc{' '}
        <a
          className="text-blue-600 underline"
          href={info.result?.facebook}
          target="_blank"
          rel="noreferrer"
        >
          Facebook
        </a>{' '}
        cá nhân.
      </div>
    </div>
  )
}

export default HomePage
