import {useEffect} from 'react'
import {useAtomValue} from 'jotai'
import {useUpdateAtom} from 'jotai/utils'
import {removeToastAtom, toastsAtom} from 'atoms/toasts'
import {
  CircleCheckIcon,
  CircleExclamationIcon,
  CircleInfoIcon,
  TriangleExclamationIcon,
  XMarkIcon,
} from './icons'

function Toast() {
  const toasts = useAtomValue(toastsAtom)
  const removeToast = useUpdateAtom(removeToastAtom)

  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(() => {
        removeToast(toasts.length - 1)
      }, 4000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [toasts, removeToast])

  if (toasts.length <= 0) return null

  return (
    <div className="fixed top-[15%] right-3 z-[999]">
      {toasts.slice(0, 5).map((toast, index) => (
        <div
          className="mb-2 flex w-[20rem] rounded-md border border-secondary bg-black px-3 py-2 text-white shadow-md shadow-black"
          key={index}
        >
          {toast.variant === 'info' ? (
            <CircleInfoIcon
              className="mt-1 mr-4 fill-sky-400"
              width={20}
              height={20}
            />
          ) : null}
          {toast.variant === 'success' ? (
            <CircleCheckIcon
              className="mt-1 mr-4 fill-green-400"
              width={20}
              height={20}
            />
          ) : null}
          {toast.variant === 'warning' ? (
            <TriangleExclamationIcon
              className="mt-1 mr-4 fill-yellow-300"
              width={20}
              height={20}
            />
          ) : null}
          {toast.variant === 'danger' ? (
            <CircleExclamationIcon
              className="mt-1 mr-4 fill-red-600"
              width={20}
              height={20}
            />
          ) : null}
          <div className="flex-1 text-[#ddd]">
            <p className="mb-1 font-bold">{toast.title}</p>
            <p className="text-sm text-gray-400">{toast.message}</p>
          </div>
          <XMarkIcon
            className="mt-1 ml-3 fill-secondary hover:cursor-pointer"
            width={18}
            height={18}
            onClick={() => removeToast(index)}
          />
        </div>
      ))}
    </div>
  )
}

export default Toast
