type ConfirmIconProps = {} & JSX.IntrinsicElements['svg']

function ConfirmIcon(props: ConfirmIconProps) {
  return (
    <svg
      fill="#fff"
      width={30}
      height={30}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      {' '}
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path
          d="M2385 5114 c-27 -2 -113 -14 -190 -25 -451 -66 -822 -223 -1199 -506
        -113 -86 -342 -309 -431 -422 -592 -749 -726 -1723 -359 -2598 128 -305 299
        -558 549 -808 414 -415 901 -653 1505 -736 140 -20 487 -17 630 5 258 39 450
        92 661 181 403 171 737 424 1009 766 286 358 461 765 531 1230 25 171 30 450
        10 629 -66 588 -323 1124 -740 1540 -471 470 -1082 730 -1746 744 -99 2 -202
        2 -230 0z m520 -338 c661 -106 1240 -500 1584 -1080 266 -448 368 -1002 280
        -1516 -167 -971 -968 -1731 -1946 -1845 -532 -62 -1062 64 -1503 357 -253 169
        -521 447 -672 699 -224 371 -328 749 -328 1188 0 490 159 950 469 1356 65 85
        241 265 344 351 326 274 722 444 1157 500 136 17 478 11 615 -10z"
        />
        <path
          d="M3650 3399 c-14 -5 -319 -303 -677 -662 l-653 -652 -307 307 c-272
        271 -313 308 -352 318 -81 21 -172 -35 -187 -115 -16 -88 -23 -80 389 -492
        334 -334 387 -383 420 -388 94 -17 50 -55 830 723 395 394 725 729 733 744 19
        37 18 106 -4 148 -32 61 -126 95 -192 69z"
        />
      </g>
    </svg>
  )
}

export default ConfirmIcon
