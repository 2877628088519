import {memo, useEffect, useMemo, useState} from 'react'
import clsx from 'clsx'
import type {Order, QRData} from 'data/orders'
import type {BankAccount} from 'data/settings'
import {useCreateOrderQR} from 'data/orders'
import Spinner from 'components/spinner'
import BankSelect from './bank-select'
import TransferNote from './transfer-note'

type PaymentMethodProps = {
  order: Order
}

function PaymentMethod({order}: PaymentMethodProps) {
  const [tab, setTab] = useState(0)
  const [selectedBank, setSelectedBank] = useState<BankAccount | null>(null)
  const [qrCodes, setQRCodes] = useState<(QRData & {bankId: number})[]>([])
  const {isLoading: qrLoading, mutateAsync: createOrderQR} = useCreateOrderQR(
    order._id
  )

  const transferContent = 'VNQR' + order._id

  const vnCurrency = useMemo(
    () =>
      new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
      }),
    []
  )

  useEffect(() => {
    setSelectedBank(null)
  }, [tab])

  async function handleSelectQRBank(bankAcc: BankAccount) {
    setSelectedBank(bankAcc)
    if (bankAcc && qrCodes.findIndex((c) => c.bankId === bankAcc.bin) < 0) {
      const data = await createOrderQR(bankAcc.bin)
      setQRCodes((code) => [...code, {...data.result, bankId: bankAcc.bin}])
    }
  }

  return (
    <>
      <div className="mt-3 mb-6 flex justify-center">
        <div
          className={clsx('mx-2 border-b-4 py-2 font-medium', {
            'border-secondary text-[#ddd]': tab === 0,
            'border-transparent text-gray-600 hover:cursor-pointer': tab !== 0,
          })}
          onClick={() => setTab(0)}
        >
          QR CODE
        </div>
        <div
          className={clsx('mx-2 border-b-4 py-2 font-medium', {
            'border-secondary text-[#ddd]': tab === 1,
            'border-transparent text-gray-600 hover:cursor-pointer': tab !== 1,
          })}
          onClick={() => setTab(1)}
        >
          CHUYỂN KHOẢN
        </div>
      </div>
      <div className="relative mb-5 min-h-[300px]">
        {tab === 0 && qrLoading ? <Spinner center /> : null}
        {tab === 0 ? (
          <BankSelect
            selected={selectedBank}
            onSelect={handleSelectQRBank}
            amount={order.amount}
          />
        ) : null}
        {tab === 0 && selectedBank ? (
          <>
            {qrCodes.map(
              (qr) =>
                qr.bankId === selectedBank.bin && (
                  <img
                    key={qr.bankId}
                    className="mx-auto mt-5"
                    width={250}
                    height={250}
                    src={qr.qrDataURL}
                    alt="QR Code"
                  />
                )
            )}
            <div className="mt-4">
              <TransferNote
                amount={vnCurrency.format(order.amount)}
                transferContent={transferContent}
              />
            </div>
          </>
        ) : null}
        {tab === 1 ? (
          <BankSelect
            selected={selectedBank}
            onSelect={setSelectedBank}
            amount={order.amount}
          />
        ) : null}
        {tab === 1 && selectedBank ? (
          <div className="mt-7">
            <h4 className="text-center font-medium">
              Vui lòng chuyển khoản theo thông tin sau:
            </h4>
            <div className="mt-5 md:mx-5">
              <p className="mb-1">
                <b className="text-secondary">Ngân hàng:</b>&nbsp; &nbsp;&nbsp;
                &nbsp;[{selectedBank.shortName}] - {selectedBank.name}
              </p>
              <p className="mb-1">
                <b className="text-secondary">Số tài khoản: </b>&nbsp; &nbsp;
                {selectedBank.accountNo}
              </p>
              <p className="mb-1">
                <b className="text-secondary">Tên tài khoản: </b> &nbsp;
                {selectedBank.accountName}
              </p>
              <p>
                <b className="text-secondary">Số tiền:</b>&nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp;{vnCurrency.format(order.amount)}
              </p>
              <p>
                <b className="text-secondary">Nội dung: </b> &nbsp; &nbsp;
                &nbsp; &nbsp;&nbsp;{transferContent}
              </p>
            </div>
            <div className="mt-4">
              <TransferNote
                amount={vnCurrency.format(order.amount)}
                transferContent={transferContent}
              />
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}

export default memo(PaymentMethod)
