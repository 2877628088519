const settingKeys = {
  all: ['settings'] as const,
  rate: () => [...settingKeys.all, 'rate'] as const,
  info: () => [...settingKeys.all, 'info'] as const,
  banks: () => [...settingKeys.all, 'banks'] as const,
  banksList: () => [...settingKeys.all, 'banksList'] as const,
  sellOrder: () => [...settingKeys.all, 'sellorder'] as const,
}

export default settingKeys
