import Router from 'next/router'
import client from 'lib/api-client'
import queryClient from 'lib/query-client'
import type {GetAuthState, GetClaims, LoginUser, Register} from './types'
import authKeys from './keys'
import {BaseResponse} from 'data/orders'

const endpoint = '/api/auth'
const endpointClaims = '/api/user/claims'

export async function login() {
  await queryClient.invalidateQueries(authKeys.currentUser)
  Router.push(endpoint + '/steam')
}

export async function logout() {
  return client.delete(endpoint + '/logout')
}

export function getAuthState() {
  return client.get<BaseResponse<GetAuthState>>(endpoint + '/user')
}

export function Register(body: Register) {
  return client.post<BaseResponse<any>>(endpoint + '/register', {
    body,
  })
}

export function getClaims() {
  return client.get<BaseResponse<GetClaims>>(endpointClaims)
}
export function requestClaims() {
  return client.get<BaseResponse<boolean>>(endpointClaims + '/request')
}
