type CashOutIconProps = {} & JSX.IntrinsicElements['svg']

function CashOutIcon(props: CashOutIconProps) {
  return (
    <svg 
      fill="#ddd"
      xmlns="http://www.w3.org/2000/svg"
      width={30} height={30}  
      {...props} 
      viewBox="0 0 200.000000 200.000000">

<g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
<path d="M284 1716 l-34 -34 0 -266 c0 -219 3 -272 15 -296 25 -49 60 -60 190
-60 l115 0 0 130 0 130 -49 0 c-40 0 -53 4 -65 22 -22 31 -20 100 3 129 l19
24 522 0 522 0 19 -24 c23 -29 25 -98 3 -129 -12 -18 -25 -22 -65 -22 l-49 0
0 -130 0 -130 115 0 c130 0 165 11 190 60 12 24 15 77 15 296 l0 266 -34 34
-34 34 -682 0 -682 0 -34 -34z"/>
<path d="M620 860 l0 -580 380 0 380 0 0 580 0 581 -67 -3 -68 -3 -3 -446 -2
-445 -41 -18 c-41 -18 -79 -66 -79 -98 0 -16 -12 -18 -119 -18 l-118 0 -6 28
c-9 42 -44 80 -88 93 l-39 12 0 449 0 448 -65 0 -65 0 0 -580z"/>
<path d="M915 1143 c-70 -37 -105 -95 -105 -173 0 -89 61 -172 136 -186 99
-18 184 18 224 96 34 67 21 169 -28 223 -48 54 -163 74 -227 40z m35 -107 c0
-8 -9 -18 -20 -21 -30 -10 -44 -42 -31 -71 21 -46 81 -23 96 38 20 80 102 90
128 16 4 -10 11 -18 17 -18 13 0 13 -17 0 -33 -6 -7 -19 -24 -31 -39 -21 -29
-49 -37 -49 -15 0 7 10 17 23 22 25 12 36 67 16 91 -29 35 -76 8 -94 -56 -11
-37 -35 -60 -63 -60 -31 0 -62 26 -62 52 0 10 -7 18 -15 18 -8 0 -15 5 -15 10
0 6 6 10 14 10 8 0 16 11 20 23 8 33 66 61 66 33z"/>
</g>
</svg>
  )
}

export default CashOutIcon
