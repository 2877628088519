import clsx from 'clsx'
import Link from 'next/link'

export type LogoProps = {
  href?: string
} & JSX.IntrinsicElements['img']

const LogoImg = (props: any) => (
  <img
    className={clsx('hover:cursor-pointer', props.className)}
    src="/logo.png"
    alt="logo"
    width={55}
    {...props}
  />
)

function Logo({className, href, ...props}: LogoProps): JSX.Element {
  if (href) {
    return (
      <Link href={href} passHref>
        <div className={clsx('flex hover:cursor-pointer', className)}>
          <LogoImg {...props} />
          <a className="ml-2 hidden font-medium md:inline">520CSGO</a>
        </div>
      </Link>
    )
  }

  return <LogoImg className={className} {...props} />
}

export default Logo
