import Link from 'next/link'
import {useAtom} from 'jotai'
import {sidebarCollapsedAtom} from 'atoms/ui'
import {useAuth} from 'data/auth'
import {CartButton} from './cart'
import Logo from './logo'
import Spinner from './spinner'
import UserDropdown from './user-dropdown'
import {LoginButton} from './button'
import {HamburgerIcon, UserCircleIcon} from './icons'

function Header(): JSX.Element {
  const [, setSidebarCollapsed] = useAtom(sidebarCollapsedAtom)

  function handleClickMenu() {
    setSidebarCollapsed(false)
  }

  return (
    <header className="sticky top-0 z-[998] flex h-16 w-full items-center justify-between border-b border-secondary bg-primary px-2 md:justify-end md:px-3">
      <HamburgerIcon
        className="cursor-pointer fill-secondary md:hidden"
        onClick={handleClickMenu}
      />
      <Logo href="/" className="md:hidden" />
      <RightHeader />
    </header>
  )
}

function RightHeader() {
  const {isFetching, data} = useAuth()

  if (isFetching) {
    return <Spinner size="sm" />
  }

  if (data && data.result?.user) {
    return (
      <div className="flex items-center">
        <CartButton />
        <UserDropdown user={data.result.user} />
      </div>
    )
  }

  return (
    <>
      <Link href="/dang-nhap">
        <a className="md:hidden">
          <UserCircleIcon width={32} height={32} className="fill-secondary" />
        </a>
      </Link>
      <LoginButton className="hidden py-1 md:inline-block" />
    </>
  )
}

export default Header
