import {useEffect, useState} from 'react'
import {useRouter} from 'next/router'
import Link from 'next/link'
import clsx from 'clsx'
import {useUpdateAtom} from 'jotai/utils'
import {showToastAtom} from 'atoms/toasts'
import type {User} from 'data/users'
import {useLogout} from 'data/auth'
import {useCountOrderNotify} from 'data/orders'
import {CarretDownIcon} from './icons'
import Spinner from './spinner'

function DropdownItem({
  className,
  ...props
}: JSX.IntrinsicElements['li']): JSX.Element {
  return (
    <li
      {...props}
      className={clsx(
        'block rounded-sm border-t border-secondary px-3 py-1 first:border-0 hover:cursor-pointer hover:bg-secondary',
        className
      )}
    />
  )
}

type UserDropdownProps = {
  user: User
}

function UserDropdown({user}: UserDropdownProps): JSX.Element {
  const router = useRouter()
  const [openContent, setOpenContent] = useState(false)
  const {data: notifyCount} = useCountOrderNotify()
  const showToast = useUpdateAtom(showToastAtom)
  const logout = useLogout()

  useEffect(() => {
    setOpenContent(false)
  }, [router.pathname])

  useEffect(() => {
    if (notifyCount && notifyCount?.result > 0) {
      showToast({
        variant: 'info',
        title: 'Giao dịch',
        message: `Bạn có ${notifyCount.result} giao dịch mới đã xử lý xong`,
      })
    }
  }, [notifyCount, showToast])

  function handleClickDropdown() {
    setOpenContent((prev) => !prev)
  }

  async function handleClickLogout() {
    await logout.mutateAsync()
    router.replace('/')
  }

  if (logout.isLoading) {
    return <Spinner />
  }

  return (
    <div className="relative">
      <div
        className="flex items-center hover:cursor-pointer"
        onClick={handleClickDropdown}
      >
        <div className="relative h-10 w-10 flex-1 rounded-full md:h-12 md:w-12">
          <img className="rounded-full" src={user.avatar.full} alt="avatar" />
          {notifyCount && notifyCount?.result > 0 ? (
            <div className="absolute top-1 -right-1 h-3 w-3 rounded-full bg-yellow-500"></div>
          ) : null}
        </div>
        <div className="hidden px-4 md:block">
          <p className="font-semibold text-secondary">{user.name.personal}</p>
          <p className="text-xs">{user.name.real}</p>
        </div>
        <CarretDownIcon className="hidden md:block" width={18} height={18} />
      </div>
      <ul
        className={clsx(
          'absolute right-0 mt-1 w-52 rounded-md border-2 border-secondary bg-black px-1 py-2',
          {hidden: !openContent}
        )}
      >
        <Link href="/giao-dich" passHref>
          <a>
            <DropdownItem className="flex items-center justify-between">
              <span>Lịch sử mua hàng</span>
              {notifyCount?.result ? (
                <div className="flex h-4 w-4 items-center justify-center rounded-full bg-yellow-500 p-1 text-xs font-bold text-black">
                  <span>{notifyCount.result}</span>
                </div>
              ) : null}
            </DropdownItem>
          </a>
        </Link>

        <Link href="/tich-luy" passHref>
          <a>
            <DropdownItem className="flex items-center justify-between">
              <span>Tích lũy</span>
            </DropdownItem>
          </a>
        </Link>
        <DropdownItem
          className="text-secondary hover:text-inherit"
          onClick={handleClickLogout}
        >
          Đăng xuất
        </DropdownItem>
      </ul>
    </div>
  )
}

export default UserDropdown
