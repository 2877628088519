import {useQuery} from '@tanstack/react-query'
import type {APIError} from 'lib/api-client'
import {getCSGO500Coins} from './api'
import csgo500Keys from './keys'
import {BaseResponse} from 'data/orders'

export function useCSGO500Coins() {
  return useQuery<BaseResponse<{coins: number}>, APIError>(
    csgo500Keys.all,
    getCSGO500Coins
  )
}
