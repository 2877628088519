import {useQuery} from '@tanstack/react-query'
import type {APIError} from 'lib/api-client'
import type {Gift} from './types'
import {getGifts} from './api'
import giftKeys from './keys'
import {BaseResponse} from 'data/orders'

export function useGifts() {
  return useQuery<BaseResponse<Gift[]>, APIError>(giftKeys.list(), getGifts)
}
