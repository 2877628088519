import {memo, useMemo} from 'react'
import {useRouter} from 'next/router'
import clsx from 'clsx'
import {useAuth} from 'data/auth'
import {Gift} from 'data/gifts'
import {useAtomValue} from 'jotai'
import {useUpdateAtom} from 'jotai/utils'
import {addOrRemoveCartGiftAtom, cartGiftsAtom} from 'atoms/cart'
import {CartPlusIcon, CheckIcon} from 'components/icons'

type GiftItemProps = {
  gift: Gift
  clickable?: boolean
}

function GiftItem({gift, clickable = true}: GiftItemProps) {
  const router = useRouter()
  const {data: auth} = useAuth()
  const cart = useAtomValue(cartGiftsAtom)
  const addOrRemoveCart = useUpdateAtom(addOrRemoveCartGiftAtom)
  const isCartAdded = useMemo(
    () => cart.findIndex((c) => c._id === gift._id) >= 0,
    [cart, gift]
  )

  function handleAddOrRemoveCart() {
    if (!clickable) {
      return
    }

    if (auth && auth.result?.authenticated) {
      addOrRemoveCart(gift)
    } else {
      router.push('/dang-nhap')
    }
  }

  return (
    <div
      className={clsx('group relative rounded-lg bg-black text-center', {
        'cursor-pointer': clickable,
      })}
      onClick={handleAddOrRemoveCart}
    >
      <img src="/steam-gift.png" alt={gift.value.toString()} />
      <div className="absolute top-0 right-0 h-0 w-0 border-t-[50px] border-l-[60px] border-t-blue-500 border-l-transparent"></div>
      <span className="absolute top-1 right-[4px] text-sm font-medium text-white">
        ${gift.value}
      </span>
      <div className="absolute bottom-0 w-full px-1 py-[1px] text-sm font-medium text-secondary">
        {new Intl.NumberFormat('vi-VN', {
          style: 'currency',
          currency: 'VND',
        }).format(gift.price)}
      </div>
      {clickable ? (
        <div
          className={clsx(
            'absolute top-0 z-[1] flex h-full w-full items-center justify-center rounded-lg rounded-tr-none',
            {
              'group-hover:bg-black group-hover:bg-opacity-60': !isCartAdded,
              'bg-black bg-opacity-60': isCartAdded,
            }
          )}
        >
          <CartPlusIcon
            className={clsx('fill-transparent', {
              'group-hover:fill-white': !isCartAdded,
            })}
            width={20}
            height={20}
          />
          {isCartAdded ? (
            <CheckIcon
              width={25}
              height={25}
              className="absolute top-1 left-1 fill-green-500"
            />
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

export default memo(GiftItem)
