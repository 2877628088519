import type {GameItem} from 'data/game-items'
import type {Gift} from 'data/gifts'
import type {CSGO500OrderItem} from 'data/csgo500'

export enum OrderStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILURE = 'failure',
  CANCELED = 'canceled',
}

export enum OrdersType {
  Buy = 'Buy',
  Sell = 'Sell',
  Claim = 'Claim',
}

export enum PaymentType {
  Momo = 'Momo',
  Bank = 'Bank',
}

export enum SitesType {
  Empire = 'empire',
  Csgo500 = 'csgo500',
}

export type BaseResponse<T> = {
  errorCode: string
  errorDesc: string
  result: T
}

export type Order = {
  _id: string
  type: string
  items: GameItem[]
  gifts: Gift[]
  coins: number
  csgo500: CSGO500OrderItem
  amount: number
  status: OrderStatus
  notify: boolean
  createdAt: string
  updatedAt: string
}

export type SellOrder = {
  _id: string
  type: string
  etop: {
    appId: number
    amount: number
  }
  coins: number
  csgo500: CSGO500OrderItem
  status: OrderStatus
  notify: boolean
  sendTo: string
  payment: {
    type: string
    sendTo: string
  }
  createdAt: string
  updatedAt: string
}

export type QRData = {
  qrDataURL: string
  qrCode?: unknown
}

export type CreateOrderData = {
  itemIds: number[]
  giftIds: string[]
  coins?: number
  csgo500: {
    userId: string | null
    amount: number
  }
}

export type CreateSellOrderData = {
  etop: {
    appId: number
    amount: number
  }
  coins: number
  csgo500: {
    userId: string | null
    amount: number
  }
  sites: {
    type: string
    sendTo: string
  }
  payment: {
    type: string
    sendTo: string
    bankCode: string
    bankBin: string
    bankName: string
  }
}
