import {useEffect, useState} from 'react'
import {useUpdateAtom} from 'jotai/utils'
import {showToastAtom} from 'atoms/toasts'
import {useRouter} from 'next/router'
import SignalRConnection from 'lib/signalR'
import {useAuth} from '../data/auth/queries'
import {orderStatus} from '../data/orders/status-map'

export function useSignalR() {
  const showToast = useUpdateAtom(showToastAtom)
  const router = useRouter()
  const {data: auth} = useAuth()
  const [popup, setPopup] = useState({
    open: false,
    title: '',
    message: '',
    orderId: '',
    status: '',
    variant: '',
  })

  useEffect(() => {
    if (!auth?.result.authenticated) return

    let connection: Awaited<ReturnType<typeof SignalRConnection.getConnection>>

    const setupConnection = async () => {
      connection = await SignalRConnection.getConnection()
      if (connection.connectionId) {
        connection.invoke('Init', auth?.result.user.id)
      }

      connection.on('Order', (data: {id: string; status: string}) => {
        if (Object.keys(orderStatus).includes(data.status)) {
          setPopup({
            open: true,
            title: 'Thông báo',
            orderId: data.id,
            status: data.status,
            variant: data.status === 'success' ? 'success' : 'error',
            message: orderStatus[data.status].text.toLowerCase(),
          })
        }
      })
    }

    setupConnection()

    return () => {
      if (connection) {
        connection.off('OrderStatusChanged')
      }
    }
  }, [showToast, router, auth])

  const showPopup = (
    title: string,
    message: string,
    variant: string,
    orderId: string,
    status: string
  ) => {
    setPopup({
      open: true,
      title,
      message,
      orderId,
      status,
      variant,
    })
  }

  return {popup, setPopup, showPopup}
}
