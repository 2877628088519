import {useEffect} from 'react'
import {useRouter} from 'next/router'
import Link from 'next/link'
import clsx from 'clsx'
import {useAtom} from 'jotai'
import {sidebarCollapsedAtom} from 'atoms/ui'

export type NavItemProps = {
  name: string
  href: string
  icon: JSX.Element
  textClass?: string
} & Pick<JSX.IntrinsicElements['a'], 'target' | 'rel'>

function NavItem({
  name,
  href,
  icon,
  textClass,
  ...props
}: NavItemProps): JSX.Element {
  const {pathname} = useRouter()
  const [, setSidebarCollapsed] = useAtom(sidebarCollapsedAtom)

  useEffect(() => {
    setSidebarCollapsed(true)
  }, [pathname, setSidebarCollapsed])

  return (
    <Link {...props} href={href} passHref className="w-60">
      <div className="border-t border-secondary py-1 first:border-0">
        <li
          className={clsx(
            'flex rounded-lg py-3 px-4 hover:cursor-pointer hover:bg-secondary',
            {'bg-secondary': pathname === href}
          )}
        >
          <div className="mr-5">{icon}</div>
          <p className={clsx('text uppercase', textClass)}>{name}</p>
        </li>
      </div>
    </Link>
  )
}

export default NavItem
