import client from 'lib/api-client'
import type {CSGO500User} from './types'
import {BaseResponse} from 'data/orders'

const endpoint = '/api/csgo500'

export function getCSGO500Coins() {
  return client.get<BaseResponse<{coins: number}>>(`${endpoint}/coins`)
}

export function checkUserId(userId: string) {
  return client.get<BaseResponse<CSGO500User>>(
    `${endpoint}/checkuser/${userId}`
  )
}
