import {memo, useMemo} from 'react'
import {useRouter} from 'next/router'
import clsx from 'clsx'
import {useAtomValue} from 'jotai'
import {useUpdateAtom} from 'jotai/utils'
import {addOrRemoveCartItemAtom, cartItemsAtom} from 'atoms/cart'
import {useAuth} from 'data/auth'
import {GameItem, GameType} from 'data/game-items'
import {CartPlusIcon, CheckIcon} from '../icons'

type GameItemCardProps = {
  item: GameItem
  clickable?: boolean
}

function GameItemCard({item, clickable = true}: GameItemCardProps) {
  const router = useRouter()
  const {data: auth} = useAuth()
  const cart = useAtomValue(cartItemsAtom)
  const addOrRemoveCart = useUpdateAtom(addOrRemoveCartItemAtom)
  const isCartAdded = useMemo(
    () => cart.findIndex((c) => c.id === item.id) >= 0,
    [cart, item]
  )

  function handleAddOrRemoveCart() {
    if (!clickable) {
      return
    }

    if (auth && auth.result?.authenticated) {
      addOrRemoveCart(item)
    } else {
      router.push('/dang-nhap')
    }
  }

  return (
    <div
      className={clsx('relative rounded-md border bg-black bg-opacity-30', {
        'group cursor-pointer': clickable,
      })}
      style={{borderColor: item.rarity.color}}
      onClick={handleAddOrRemoveCart}
    >
      <div className="relative">
        <img className="rounded-t-md" src={item.image} alt={item.name} />
        {item.appid !== GameType.CSGO ? (
          <div
            className="absolute bottom-0 w-full bg-black bg-opacity-60 py-[0.5px] text-center text-xs"
            style={{color: item.rarity.color}}
          >
            {item.rarity.name}
          </div>
        ) : null}
        <div className="absolute top-1 right-1 rounded-md bg-black bg-opacity-80 px-[2.5px] text-[11px] text-white">
          ${item.value}
        </div>
        <div className="absolute top-1 left-1 rounded-md bg-black bg-opacity-80 px-[2.5px] text-[11px] text-white">
          {item?.status?.new == 1 ? 'N' : ''}
        </div>
      </div>
      <div className="p-1">
        <p className="text-center text-xs" style={{color: item.name_color}}>
          {item.name}
        </p>
      </div>
      {clickable ? (
        <div
          className={clsx(
            'absolute left-0 top-0 h-full w-full rounded-md',
            'flex items-center justify-center',
            {
              'group-hover:bg-black group-hover:bg-opacity-60': !isCartAdded,
              'bg-black bg-opacity-60': isCartAdded,
            }
          )}
        >
          <CartPlusIcon
            className={clsx('fill-transparent', {
              'group-hover:fill-white': !isCartAdded,
            })}
            width={18}
            height={18}
          />
          {isCartAdded ? (
            <CheckIcon
              width={25}
              height={25}
              className="absolute top-1 left-1 fill-green-500"
            />
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

export default memo(GameItemCard)
