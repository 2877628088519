type CoinsIconProps = {} & JSX.IntrinsicElements['svg']

function CoinsIcon(props: CoinsIconProps) {
  return (
    <svg
      fill="#fff"
      width={30}
      height={30}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 612 612"
    >
      <path d="M610.434,512.716l-95.988-296.691c-4.244-13.117-16.459-22.003-30.245-22.003H382.904 c8.211-13.563,13.028-29.399,13.028-46.379c0-49.586-40.346-89.933-89.933-89.933c-49.586,0-89.933,40.346-89.933,89.933 c0,16.979,4.817,32.815,13.029,46.379H127.8c-13.786,0-26.001,8.886-30.245,22.003L1.567,512.716 c-6.643,20.531,8.666,41.573,30.245,41.573h548.376C601.768,554.29,617.076,533.248,610.434,512.716z M258.452,147.643 c0-26.221,21.327-47.548,47.548-47.548c26.221,0,47.548,21.327,47.548,47.548c0,22.705-16.015,41.682-37.327,46.379H295.78 C274.467,189.326,258.452,170.348,258.452,147.643z M263.962,451.032l-27.475-52.756h-13.092v52.756h-31.83V320.978h31.83v50.208 h13.092l26.927-50.208h34.198l-35.117,62.205v0.37l37.116,67.479H263.962z M431.369,451.032h-26.557v-3.644 c0-2.726,0.178-5.45,0.178-5.45h-0.355c0,0-12.56,11.271-34.198,11.271c-33.294,0-63.85-24.912-63.85-67.479 c0-37.827,28.555-66.931,68.219-66.931c33.28,0,50.017,17.271,50.017,17.271l-15.285,23.831c0,0-13.271-11.997-31.65-11.997 c-27.282,0-38.375,17.462-38.375,36.73c0,24.75,17.092,39.472,37.294,39.472c15.27,0,26.362-9.449,26.362-9.449v-10.013h-18.365 v-27.104h46.565L431.369,451.032L431.369,451.032z"></path>{' '}
    </svg>
  )
}

export default CoinsIcon
