import {atom} from 'jotai'
import {GameType} from 'data/game-items'
import type {GameItem} from 'data/game-items'
import type {Gift} from 'data/gifts'
import type {CSGO500OrderItem} from 'data/csgo500'

export const cartItemsAtom = atom<GameItem[]>([] as GameItem[])

export const totalValueDotaCartAtom = atom(
  (get) =>
    +get(cartItemsAtom)
      .reduce(
        (total, cart) =>
          cart.appid === GameType.DOTA ? (total += cart.value) : total,
        0
      )
      .toFixed(2)
)

export const totalValueCsgoCartAtom = atom(
  (get) =>
    +get(cartItemsAtom)
      .reduce(
        (total, cart) =>
          cart.appid === GameType.CSGO ? (total += cart.value) : total,
        0
      )
      .toFixed(2)
)

export const addOrRemoveCartItemAtom = atom(
  null,
  (get, set, item: GameItem) => {
    const cart = get(cartItemsAtom)
    if (cart.findIndex((i) => i.id === item.id) >= 0) {
      return set(
        cartItemsAtom,
        cart.filter((c) => c.id !== item.id)
      )
    }
    return set(cartItemsAtom, [...cart, item])
  }
)

export const cartGiftsAtom = atom<Gift[]>([])

export const totalCartGiftsPriceAtom = atom((get) =>
  get(cartGiftsAtom).reduce((total, cart) => (total += cart.price), 0)
)

export const addOrRemoveCartGiftAtom = atom(null, (get, set, gift: Gift) => {
  const cart = get(cartGiftsAtom)
  if (cart.findIndex((g) => g._id === gift._id) >= 0) {
    return set(
      cartGiftsAtom,
      cart.filter((g) => g._id !== gift._id)
    )
  }
  return set(cartGiftsAtom, [...cart, gift])
})

export const cartCoinsAtom = atom(0)

export const cart500CoinsAtom = atom<CSGO500OrderItem | undefined>(undefined)

export const emptyCartAtom = atom(null, (_, set) => {
  set(cartGiftsAtom, [])
  set(cartItemsAtom, [])
  set(cartCoinsAtom, 0)
  set(cart500CoinsAtom, undefined)
})
