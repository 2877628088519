import SidebarContent from './sidebar-content'

function Sidebar() {
  return (
    <div className="fixed left-0 z-[999] hidden h-full md:block md:w-60">
      <SidebarContent />
    </div>
  )
}

export default Sidebar
