import React, {useEffect, useState} from 'react'
import {Dialog} from '@mui/material'
import {useSignalR} from '../../hooks/useSignalR'
import {useRouter} from 'next/router'
import ConfirmIcon from '../icons/confirm'
import CloseIcon from '../icons/close'
import WarningIcon from '../icons/warning'
import {OrderStatus} from 'data/orders/types'

const OrderPopup = () => {
  const {popup, setPopup} = useSignalR()
  const [countdown, setCountdown] = useState(5)
  const router = useRouter()

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (popup.open && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1)
      }, 1000)
    } else if (countdown === 0) {
      setPopup((prev) => ({...prev, open: false}))
      setCountdown(5)
      router.back()
    }

    return () => clearInterval(timer)
  }, [popup.open, countdown, setPopup, router])

  // Chọn biểu tượng dựa trên trạng thái
  const renderIcon = () => {
    switch (popup.status) {
      case OrderStatus.SUCCESS:
        return <ConfirmIcon className="h-16 w-16 fill-secondary" />
      case OrderStatus.FAILURE:
        return <CloseIcon className="h-16 w-16 fill-secondary" />
      case OrderStatus.CANCELED: //
        return <WarningIcon className="h-16 w-16 fill-secondary" />
      default:
        return null
    }
  }
  return (
    <>
      <Dialog
        open={popup.open}
        PaperProps={{
          style: {
            backgroundColor: 'black',
            borderRadius: '16px',
            color: 'white',
          },
        }}
        onClose={() => setPopup((prev) => ({...prev, open: false}))}
      >
        <div className={`popup ${popup.variant} rounded-xl p-8 text-center`}>
          <div className="mb-4 flex justify-center">
            {renderIcon()} {/* Hiển thị biểu tượng dựa trên trạng thái */}
          </div>
          <h2 className="mb-4 text-secondary">{popup.title}</h2>
          <p className="mb-6 text-secondary">
            Đơn hàng <strong>{popup.orderId}</strong> đã {popup.message}
          </p>
          <p className="text-sm text-secondary">
            Tự động đóng sau {countdown} giây
          </p>
        </div>
      </Dialog>
    </>
  )
}

export default OrderPopup
