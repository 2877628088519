import {OrderStatus, OrdersType} from './types'

type StatusObject = {
  [key: string]: {
    text: string
    badge: 'info' | 'success' | 'warning' | 'danger'
  }
}

export const orderStatus: StatusObject = {
  [OrderStatus.PENDING]: {
    text: 'Đang xử lý',
    badge: 'info',
  },
  [OrderStatus.SUCCESS]: {
    text: 'Thành công',
    badge: 'success',
  },
  [OrderStatus.FAILURE]: {
    text: 'Thất bại',
    badge: 'danger',
  },
  [OrderStatus.CANCELED]: {
    text: 'Bị hủy',
    badge: 'warning',
  },
}

export const ordersType: StatusObject = {
  [OrdersType.Sell]: {
    text: 'Bạn bán',
    badge: 'info',
  },
  [OrdersType.Buy]: {
    text: 'Bạn mua',
    badge: 'success',
  },
  [OrdersType.Claim]: {
    text: 'Nhận tích lũy',
    badge: 'warning',
  },
}
