import {useQuery} from '@tanstack/react-query'
import queryClient from 'lib/query-client'
import type {APIError} from 'lib/api-client'
import type {GameItem, GameType} from './types'
import {getGameItems} from './api'
import gameItemKeys from './keys'
import {BaseResponse} from 'data/orders'

export function useGameItems(
  game: GameType,
  filter: {search?: string; sort?: number} = {}
) {
  return useQuery<BaseResponse<GameItem[]>, APIError>(
    gameItemKeys.listByGame(game),
    () => getGameItems(game),
    {
      select: (data) => {
        let items = [...data.result]
        if (filter.search) {
          items = items.filter((i) =>
            i.name
              .toUpperCase()
              .includes((filter.search as string).toUpperCase())
          )
        }
        if (filter.sort && filter.sort > 0) {
          items.sort((a, b) =>
            filter.sort === 1 ? a.value - b.value : b.value - a.value
          )
        }
        return {
          errorCode: data.errorCode,
          errorDesc: data.errorDesc,
          result: data.result,
        } as BaseResponse<GameItem[]>
      },
    }
  )
}

export function prefetchGameItems(game: GameType) {
  return queryClient.prefetchQuery(gameItemKeys.listByGame(game), () =>
    getGameItems(game)
  )
}
