import {useQuery} from '@tanstack/react-query'
import type {APIError} from 'lib/api-client'
import {getCoins} from './api'
import coinKeys from './keys'
import {BaseResponse} from 'data/orders'

export function useCoins() {
  return useQuery<BaseResponse<{coins: number}>, APIError>(
    coinKeys.all,
    getCoins
  )
}
