import {memo, useState} from 'react'
import type {Gift} from 'data/gifts'
import {EyeIcon, EyeSlashIcon, SteamIcon} from 'components/icons'

type GiftCodeProps = {
  gift: Gift
}

function GiftCode({gift}: GiftCodeProps) {
  const [show, setShow] = useState(false)

  return (
    <div className="relative rounded-lg border border-secondary py-1">
      <div className="absolute top-0 right-2">
        {!show ? (
          <EyeIcon
            className="cursor-pointer"
            width={18}
            onClick={() => setShow(true)}
          />
        ) : (
          <EyeSlashIcon
            className="cursor-pointer"
            width={18}
            onClick={() => setShow(false)}
          />
        )}
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="font-medium">Gift Code</div>
        <div className="my-1 flex h-[30px] items-center justify-center">
          {!show ? (
            <SteamIcon height={28} />
          ) : (
            <p className="text-center text-sm">{gift.code}</p>
          )}
        </div>
        <div className="text-sm font-medium text-secondary">${gift.value}</div>
      </div>
    </div>
  )
}

export default memo(GiftCode)
