import {useMutation, useQueryClient} from '@tanstack/react-query'
import {useUpdateAtom} from 'jotai/utils'
import {emptyCartAtom} from 'atoms/cart'
import {showToastAtom} from 'atoms/toasts'
import type {APIError} from 'lib/api-client'
import {GameType, gameItemKeys} from 'data/game-items'
import {giftKeys} from 'data/gifts'
import {coinKeys} from 'data/coins'
import {csgo500Keys} from 'data/csgo500'
import type {Order, QRData, CreateSellOrderData, BaseResponse} from './types'
import {
  createOrder,
  createSellOrder,
  createOrderQR,
  updateOrdersNotify,
} from './api'
import orderKeys from './keys'

export function useCreateOrder() {
  const queryClient = useQueryClient()
  const emptyCart = useUpdateAtom(emptyCartAtom)
  const showToast = useUpdateAtom(showToastAtom)

  return useMutation(createOrder, {
    onSuccess: ({result}) => {
      emptyCart()
      showToast({
        variant: 'success',
        title: 'Đặt hàng thành công',
        message: 'Bạn đã đặt hàng thành công',
      })
      if (
        result.items.filter((item) => item.appid === GameType.DOTA).length > 0
      ) {
        queryClient.invalidateQueries(gameItemKeys.listByGame(GameType.DOTA))
      }
      if (
        result.items.filter((item) => item.appid === GameType.CSGO).length > 0
      ) {
        queryClient.invalidateQueries(gameItemKeys.listByGame(GameType.CSGO))
      }
      if (result.gifts.length > 0) {
        queryClient.invalidateQueries(giftKeys.list())
      }
      if (result.coins > 0) {
        queryClient.invalidateQueries(coinKeys.all)
      }
      if (result.csgo500) {
        queryClient.invalidateQueries(csgo500Keys.coins())
      }
      queryClient.invalidateQueries(orderKeys.lists())
      return
    },
    onError: (err: any) => {
      if (err.statusCode >= 409) {
        emptyCart()
        showToast({
          variant: 'danger',
          title: 'Lỗi trùng lặp',
          message:
            'Một số vật phẩm đang được xử lý bởi người khác, vui lòng thêm lại vật phẩm vào giỏ hàng',
        })
        queryClient.invalidateQueries(gameItemKeys.listByGame(GameType.DOTA))
        queryClient.invalidateQueries(gameItemKeys.listByGame(GameType.CSGO))
        queryClient.invalidateQueries(giftKeys.list())
        queryClient.invalidateQueries(coinKeys.all)
      }
      return
    },
  })
}

export function useCreateSellOrder() {
  const showToast = useUpdateAtom(showToastAtom)
  return useMutation(createSellOrder, {
    onSuccess: ({result}) => {
      // showToast({
      //   variant: 'success',
      //   title: 'Đặt hàng thành công',
      //   message: 'Bạn đã đặt hàng thành công',
      // })
      return
    },
    onError: (err: any) => {
      if (err.statusCode >= 409) {
        showToast({
          variant: 'danger',
          title: 'Lỗi trùng lặp',
          message:
            'Một số vật phẩm đang được xử lý bởi người khác, vui lòng thêm lại vật phẩm vào giỏ hàng',
        })
      }
      return
    },
  })
}

export function useUpdatOrdersNotify() {
  return useMutation((orders: Order[]) =>
    updateOrdersNotify(orders.map((order) => order._id))
  )
}

export function useCreateOrderQR(orderId: string) {
  const showToast = useUpdateAtom(showToastAtom)
  return useMutation<BaseResponse<QRData>, APIError, number>(
    (bankBin) => createOrderQR(orderId, bankBin),
    {
      onError: (err) => {
        if (err.statusCode === 409) {
          showToast({
            variant: 'warning',
            title: 'Đơn hàng đã xử lý',
            message: 'Đơn hàng đã xử lý không thể tiếp tục tạo mã QR',
          })
        }
        return
      },
    }
  )
}
