import {memo} from 'react'
import clsx from 'clsx'
import {useAtom} from 'jotai'
import {cartCoinsAtom} from 'atoms/cart'
import {CoinsIcon} from 'components/icons'

type CoinsCardProps = {
  coins: number
  small?: boolean
  clickable?: boolean
}

function CoinsCard({coins, clickable = true, small = false}: CoinsCardProps) {
  const [, setCartCoins] = useAtom(cartCoinsAtom)

  if (coins <= 0) return null

  return (
    <div
      className={clsx(
        'flex flex-col items-center rounded-md border-2 border-yellow-500 bg-black',
        {
          'cursor-pointer': clickable,
          'w-28 py-3': !small,
          'w-[6.2rem] py-2': small,
        }
      )}
      onClick={() => clickable && setCartCoins(0)}
    >
      <CoinsIcon className="my-1 fill-yellow-500" />
      <div className={clsx('my-[0.2rem] font-medium text-yellow-500')}>
        {coins}
      </div>
      <div className="text-yello-500 text-center text-xs font-medium">
        ĐẾ CHẾ
      </div>
    </div>
  )
}

export default memo(CoinsCard)
