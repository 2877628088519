import clsx from 'clsx'
import {useAtom} from 'jotai'
import {sidebarCollapsedAtom} from 'atoms/ui'
import SidebarContent from './sidebar-content'

function Drawer() {
  const [sidebarCollapsed] = useAtom(sidebarCollapsedAtom)

  return (
    <SidebarContent
      className={clsx(
        'fixed left-0 top-0 z-[999] w-full transition-all duration-200 md:hidden',
        {
          'w-0 overflow-hidden': sidebarCollapsed,
        }
      )}
    />
  )
}

export default Drawer
