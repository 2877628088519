type CSGOIconProps = {} & JSX.IntrinsicElements['svg']

function CSGOIcon(props: CSGOIconProps) {
  return (
    <svg
      fill="#ddd"
      width={30}
      height={30}
      {...props}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
    >
      <g>
        <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
          <path d="M1321.2,3364.8c-130.7-164.9-126.7-162.9-295.6-177c-175-12.1-239.3-38.2-259.4-102.6c-8-22.1-20.1-263.5-28.2-537c-14.1-438.5-20.1-508.8-58.3-607.4c-72.4-181-52.3-299.7,52.3-329.8c18.1-4,88.5-20.1,154.9-32.2c356-70.4,577.2-315.8,577.2-639.6c0-321.8-257.4-953.3-623.5-1530.5C635.4-917,237.2-1725.5,164.8-1962.9c-94.5-319.8-92.5-339.9,52.3-480.7c62.3-58.3,404.2-241.3,452.5-241.3c46.3,0,48.3-44.2,4-134.7c-34.2-70.4-40.2-104.6-26.1-144.8c18.1-52.3,24.1-52.3,380.1-96.5c372.1-44.3,1168.5-102.6,1536.6-110.6c183-4,211.2,0,235.3,34.2c16.1,20.1,28.2,54.3,28.2,76.4c0,66.4,38.2,110.6,104.6,124.7c179,34.2,177,32.2,177,259.5c0,259.4,34.2,339.9,215.2,508.8l132.7,122.7l2,229.3c2,197.1,10.1,239.3,50.3,317.8c66.4,124.7,100.6,166.9,221.2,261.5l102.6,82.5l-4,215.2c-4,209.2-2,219.2,64.4,354c86.5,175,179,265.5,307.7,303.7c162.9,48.3,273.5,38.2,428.4-34.2l138.8-66.4l432.4,2c498.8,2,1486.3,42.2,1588.9,64.3c44.2,10.1,80.4,34.2,98.6,68.4c28.1,48.3,26.1,74.4-18.1,271.5c-42.2,183-50.3,269.5-50.3,549.1c0,388.2,10.1,422.4,175,533c179,120.7,263.5,128.7,1403.8,148.8c868.9,14.1,1031.8,22.1,1124.3,50.3c142.8,46.3,197.1,102.6,213.2,225.3c6,54.3,26.1,168.9,44.3,255.4c24.1,128.7,26.1,183,6,289.6l-22.1,132.7l58.3,54.3c56.3,50.3,58.3,58.3,72.4,313.8c14.1,295.6,2,366-86.5,448.5c-34.2,32.2-62.3,76.4-62.3,98.5c0,50.3-32.2,76.4-114.6,92.5c-34.2,8-106.6,46.3-160.9,84.5c-114.6,82.5-173,86.5-233.3,16.1c-24.1-28.2-80.4-58.3-124.7-68.4c-102.6-24.1-6868.3-72.4-7216.2-52.3L1639,3212l-86.5,88.5C1462,3393,1365.5,3419.1,1321.2,3364.8z M6216.5,1108.3c96.5-40.2,185-126.7,231.3-225.3c40.2-82.5,44.2-114.6,36.2-325.8c-12.1-341.9-76.4-458.6-299.7-537c-88.5-32.2-762.3-90.5-1033.8-90.5c-179,0-299.7,42.2-398.2,136.8C4647.8,165,4637.7,205.2,4637.7,523v293.6l82.5-6l82.5-6l46.3-146.8c94.5-295.7,257.4-464.6,462.6-482.7l108.6-8.1l-96.5,229.3c-181,422.4-239.3,673.8-177,738.1C5178.7,1170.6,6124,1146.5,6216.5,1108.3z" />
        </g>
      </g>
    </svg>
  )
}

export default CSGOIcon
