import client from 'lib/api-client'
import type {Paginate} from 'data/common/types'
import type {CSGO500OrderItem} from 'data/csgo500'
import type {
  CreateOrderData,
  CreateSellOrderData,
  Order,
  SellOrder,
  QRData,
  BaseResponse,
} from './types'

const endpoint = '/api/orders'

export function createOrder(body: CreateOrderData) {
  return client.post<BaseResponse<Order>>(endpoint, {
    body,
  })
}

export function createSellOrder(body: CreateSellOrderData) {
  return client.post<BaseResponse<SellOrder>>(endpoint + '/sellorder', {
    body,
  })
}

export function getOrders(page: number = 1, pageSize: number = 5) {
  return client.get<BaseResponse<Paginate<Order[]>>>(endpoint, {
    query: {page, pageSize},
  })
}

export function getOrderNotifyCount() {
  return client.get<BaseResponse<number>>(endpoint + '/notify')
}

export function updateOrdersNotify(orderIds: string[]) {
  return client.put<BaseResponse<boolean>>(endpoint + '/notify', {
    body: {orderIds},
  })
}

export function createOrderQR(orderId: string, bankBin: number) {
  return client.post<BaseResponse<QRData>>(endpoint + `/${orderId}/qr`, {
    body: {
      bankBin,
    },
  })
}
