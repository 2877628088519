import {memo} from 'react'
import type {Gift} from 'data/gifts'
import GiftItem from './gift-item'

type ListGiftProps = {
  gifts: Gift[]
  clickable?: boolean
} & JSX.IntrinsicElements['div']

function ListGift({gifts, clickable = true, ...props}: ListGiftProps) {
  return (
    <div {...props}>
      {gifts.map((gift) => (
        <GiftItem key={gift._id} gift={gift} clickable={clickable} />
      ))}
    </div>
  )
}

export default memo(ListGift)
