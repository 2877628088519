import {useQuery, useQueryClient} from '@tanstack/react-query'
import type {APIError} from 'lib/api-client'
import type {Paginate} from 'data/common/types'
import type {BaseResponse, Order} from './types'
import {getOrderNotifyCount, getOrders} from './api'
import orderKeys from './keys'

export function useOrders(page: number) {
  return useQuery<BaseResponse<Paginate<Order[]>>, APIError>(
    orderKeys.listByPage(page),
    () => getOrders(page),
    {
      keepPreviousData: true,
    }
  )
}

export function useCountOrderNotify() {
  const queryClient = useQueryClient()
  return useQuery<BaseResponse<number>, APIError>(
    orderKeys.notifyCount(),
    getOrderNotifyCount,
    {
      refetchInterval: 30 * 1000,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data?.result > 0) {
          return queryClient.invalidateQueries(orderKeys.lists())
        }
      },
    }
  )
}
