import type {FallbackProps} from 'react-error-boundary'

function ErrorFallback({resetErrorBoundary}: FallbackProps) {
  return (
    <div className="relative min-h-screen bg-black text-white">
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <h1 className="text-xl">Có gì đó không đúng đã xảy ra.</h1>
        <button
          className="mx-auto mt-7 block rounded-md border border-secondary bg-secondary px-5 py-2 font-medium hover:bg-black"
          onClick={resetErrorBoundary}
        >
          Thử lại
        </button>
      </div>
    </div>
  )
}

export default ErrorFallback
