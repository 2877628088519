import clsx from 'clsx'

type BoxProps = {} & JSX.IntrinsicElements['div']

function Box({className, ...props}: BoxProps) {
  return (
    <div
      {...props}
      className={clsx(
        'rounded-md border border-secondary bg-black px-3 py-2 leading-8',
        className
      )}
    />
  )
}

export default Box
