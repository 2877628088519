type RateTextProps = {
  text: string
  rate: number
  formatter: Intl.NumberFormat
}

function RateText({text, rate, formatter}: RateTextProps) {
  return (
    <p className="text-sm">
      {text}: &nbsp;
      <b className="text-secondary">{formatter.format(rate)}</b>
    </p>
  )
}

export default RateText
