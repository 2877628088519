import {memo} from 'react'
import clsx from 'clsx'
import {useAtomValue} from 'jotai'
import {
  cart500CoinsAtom,
  cartCoinsAtom,
  cartGiftsAtom,
  cartItemsAtom,
} from 'atoms/cart'
import {ListGameItem} from 'components/items'
import {ListGift} from 'components/gifts'
import {CoinsCard} from 'components/coins'
import {CSGO500Card} from 'components/csgo500'

function CartSection() {
  const cartItems = useAtomValue(cartItemsAtom)
  const cartGifts = useAtomValue(cartGiftsAtom)
  const cartCoins = useAtomValue(cartCoinsAtom)
  const cart500Coins = useAtomValue(cart500CoinsAtom)
  const cart500CoinsAmount = cart500Coins?.amount ?? 0

  if (
    cartItems.length + cartGifts.length + cartCoins + cart500CoinsAmount <=
    0
  ) {
    return <p className="text-center">Không có vật phẩm nào trong giỏ hàng</p>
  }

  return (
    <>
      <ListGameItem
        className="grid grid-cols-3 gap-x-2 gap-y-4 lg:grid-cols-5"
        items={cartItems}
      />
      <ListGift
        className={clsx('grid grid-cols-3 gap-x-2 gap-y-4 lg:grid-cols-5', {
          'mt-3 border-t border-secondary pt-3':
            cartItems.length > 0 && cartGifts.length > 0,
        })}
        gifts={cartGifts}
      />
      <div
        className={clsx({
          'mt-3 border-t border-secondary pt-3':
            (cartItems.length > 0 || cartGifts.length > 0) && cartCoins > 0,
        })}
      >
        <CoinsCard coins={cartCoins} />
      </div>
      <div
        className={clsx({
          'mt-3 border-t border-secondary pt-3':
            (cartItems.length > 0 || cartGifts.length > 0 || cartCoins > 0) &&
            cart500CoinsAmount > 0,
        })}
      >
        <CSGO500Card
          userId={cart500Coins?.userId!}
          coins={cart500CoinsAmount}
        />
      </div>
    </>
  )
}

export default memo(CartSection)
