import Header from './header'
import Sidebar from './sidebar'
import Drawer from './drawer'
import {useSignalR} from 'hooks/useSignalR'

type LayoutProps = {
  children: React.ReactNode
}

function Layout({children}: LayoutProps) {
  return (
    <div className="flex min-h-screen flex-col bg-background text-[#ddd]">
      <Sidebar />
      <Drawer />
      <Header />
      <main className="flex flex-1 px-4 py-7 md:pl-64">
        <div className="relative flex-1">{children}</div>
      </main>
    </div>
  )
}

export default Layout
