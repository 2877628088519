import Head from 'next/head'

type SEOProps = {
  title: string
}

function SEO({title}: SEOProps): JSX.Element {
  return (
    <Head>
      <title>{title.concat(' - 520CSGO STORE')}</title>
      <meta name="description" content="DOTA 2, CSGO, GIFT CODE,..." />
      <link rel="icon" href="/favicon.ico" />
    </Head>
  )
}

export default SEO
