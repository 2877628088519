import {memo} from 'react'
import clsx from 'clsx'
import {useAtom} from 'jotai'
import {cart500CoinsAtom} from 'atoms/cart'
import {CoinsIcon} from 'components/icons'

type CSGO500CardProps = {
  userId: string
  coins: number
  small?: boolean
  clickable?: boolean
}

function CSGO500Card({
  userId,
  coins,
  clickable = true,
  small = false,
}: CSGO500CardProps) {
  const [, setCartCoins] = useAtom(cart500CoinsAtom)

  if (coins <= 0) return null

  return (
    <div
      className={clsx(
        'flex flex-col items-center rounded-md border-2 border-secondary bg-black',
        {
          'cursor-pointer': clickable,
          'w-full py-3 lg:w-56': !small,
          'w-[12.5rem] py-2': small,
        }
      )}
      onClick={() => clickable && setCartCoins(undefined)}
    >
      <CoinsIcon className="my-1 fill-secondary" />

      <div className={clsx('my-[0.2rem] font-medium text-secondary')}>
        {coins}
      </div>
      <div className="text-center text-sm font-bold text-secondary">500</div>
      <div
        className={clsx(
          'my-[0.2rem] max-w-full break-words text-center text-sm font-medium text-secondary'
        )}
      >
        <b>ID:</b> {userId}
      </div>
    </div>
  )
}

export default memo(CSGO500Card)
