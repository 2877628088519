import clsx from 'clsx'
import Link from 'next/link'
import {authAPI} from 'data/auth'
import {UserCircleIcon} from './icons'

type LinkButtonProps = {
  href: string
} & JSX.IntrinsicElements['a']

function LinkButton({href, className, ...props}: LinkButtonProps): JSX.Element {
  return (
    <Link href={href} passHref>
      <a
        {...props}
        className={clsx(
          'inline-block bg-secondary px-6 py-2 font-semibold',
          'rounded-full border-2 border-secondary',
          'hover:bg-inherit hover:shadow-sm',
          className
        )}
      />
    </Link>
  )
}

function LoginButton({className}: Omit<LinkButtonProps, 'href'>) {
  return (
    <LinkButton className={clsx('px-3 py-2', className)} href="/dang-nhap">
      <div className="flex items-center">
        <UserCircleIcon
          width={27}
          height={27}
          className="hover:cursor-pointer"
        />
        <span className="ml-3">Đăng nhập</span>
      </div>
    </LinkButton>
  )
}

type SteamButtonProps = {
  size?: 'sm' | 'md'
} & JSX.IntrinsicElements['img']

function SteamButton({size = 'md', className, ...props}: SteamButtonProps) {
  async function handleClickLogin() {
    await authAPI.login()
  }

  return (
    <img
      src={size === 'md' ? '/steam-medium.png' : '/steam-small.png'}
      alt="steam logo"
      onClick={handleClickLogin}
      className={clsx('hover:cursor-pointer', className)}
      {...props}
    />
  )
}

export {LinkButton, LoginButton, SteamButton}
