import {memo, useMemo, useState, useEffect} from 'react'
import {useRouter} from 'next/router'
import clsx from 'clsx'
import {useAtomValue} from 'jotai'
import {useUpdateAtom} from 'jotai/utils'
import {
  cartItemsAtom,
  totalValueDotaCartAtom,
  totalValueCsgoCartAtom,
  cartGiftsAtom,
  totalCartGiftsPriceAtom,
  cartCoinsAtom,
  cart500CoinsAtom,
} from 'atoms/cart'
import {showToastAtom} from 'atoms/toasts'
import {useAuth} from 'data/auth'
import {useSetting} from 'data/settings'
import {useCreateOrder} from 'data/orders'
import {EMPIRE_EXCHANGE, EMPIRE_RATE, tofix_2} from 'data/coins'
import {CSGO500_EXCHANGE, CSGO500_RATE, tofix_3} from 'data/csgo500'
import {ListGameItem} from 'components/items'
import {ListGift} from 'components/gifts'
import {CoinsCard} from 'components/coins'
import {CSGO500Card} from 'components/csgo500'
import Spinner from 'components/spinner'
import PaymentMethod from './payment-method'
import RateText from './rate-text'

function PaymentSection() {
  const router = useRouter()
  const {data: auth} = useAuth()
  const orderMutation = useCreateOrder()
  const createdOrder = orderMutation.data
  const {isFetching: isSettingFetching, data: setting} = useSetting()
  const cartItems = useAtomValue(cartItemsAtom)
  const cartGifts = useAtomValue(cartGiftsAtom)
  const totalValueDota = useAtomValue(totalValueDotaCartAtom)
  const totalValueCsgo = useAtomValue(totalValueCsgoCartAtom)
  const totalCartGiftsPrice = useAtomValue(totalCartGiftsPriceAtom)
  const cartCoins = useAtomValue(cartCoinsAtom)
  const cart500Coins = useAtomValue(cart500CoinsAtom)
  const showToast = useUpdateAtom(showToastAtom)
  const [timeLeft, setTimeLeft] = useState<number>(240)

  useEffect(() => {
    if (orderMutation.isSuccess && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1)
      }, 1000)
      return () => clearInterval(timer)
    } else if (timeLeft === 0) {
      showToast({
        variant: 'danger',
        title: 'Hết thời gian thanh toán',
        message: 'Đơn hàng của bạn đã bị hủy do quá thời gian thanh toán',
      })
      router.back()
    }
  }, [orderMutation.isSuccess, timeLeft, router, showToast])

  const min = 30000
  const amount = useMemo(
    () =>
      setting
        ? setting.result.rate.dota * totalValueDota +
          setting.result.rate.csgo * totalValueCsgo +
          setting.result.rate.empire * cartCoins +
          setting.result.rate.csgo500 * (cart500Coins?.amount ?? 0)
        : 0,
    [setting, totalValueDota, totalValueCsgo, cartCoins, cart500Coins]
  )
  const allowOrder = useMemo(
    () => amount >= min || totalCartGiftsPrice >= min,
    [amount, totalCartGiftsPrice]
  )
  const vnCurrency = useMemo(
    () =>
      new Intl.NumberFormat('vi-VN', {
        style: 'currency',
        currency: 'VND',
      }),
    []
  )

  function handleClickOrder() {
    if (!auth) return

    if (cartItems.length > 0 && !auth.result.user.isLoginEtop) {
      return showToast({
        variant: 'danger',
        title: 'Chưa đăng ký tài khoản',
        message: (
          <>
            Bạn chưa có tài khoản EtopFun, vui lòng đăng ký trước khi đặt hàng
            &quot;
            <a
              className="cursor-pointer font-bold text-blue-600 underline"
              href={auth.result.user.etopRef}
              target="_blank"
              rel="noreferrer"
            >
              tại đây
            </a>
            &quot;
          </>
        ),
      })
    }

    if (cartCoins > 0 && !auth.result?.user.isLoginEmpire) {
      return showToast({
        variant: 'danger',
        title: 'Chưa đăng ký tài khoản',
        message: (
          <>
            Bạn chưa có tài khoản CSGOEmpire, vui lòng đăng ký trước khi đặt
            hàng &quot;
            <a
              className="cursor-pointer font-bold text-blue-600 underline"
              href={auth.result?.user.empireRef}
              target="_blank"
              rel="noreferrer"
            >
              tại đây
            </a>
            &quot;
          </>
        ),
      })
    }

    orderMutation.mutate({
      itemIds: cartItems.map((item) => item.id),
      giftIds: cartGifts.map((gift) => gift._id),
      coins: Math.round(cartCoins * EMPIRE_RATE),
      csgo500: {
        userId: cart500Coins?.userId ?? null,
        amount: cart500Coins ? cart500Coins.amount * CSGO500_RATE : 0,
      },
    })
  }

  if (
    cartItems.length +
      cartGifts.length +
      cartCoins +
      (cart500Coins?.amount ?? 0) <=
      0 &&
    orderMutation.isIdle
  ) {
    return (
      <p className="text-center">
        Không có vật phẩm nào trong giỏ hàng để thanh toán
      </p>
    )
  }

  if (orderMutation.isLoading) {
    return <Spinner className="mx-auto" />
  }

  if (orderMutation.isIdle || orderMutation.isError) {
    return (
      <>
        <div className="relative my-8 rounded-md bg-black p-2 lg:flex">
          {isSettingFetching ? <Spinner size="sm" className="mx-auto" /> : null}
          {setting ? (
            <>
              <div className="flex-1">
                <RateText
                  text="Rate DOTA"
                  rate={setting.result?.rate.dota}
                  formatter={vnCurrency}
                />
                <RateText
                  text="Rate CSGO"
                  rate={setting.result?.rate.csgo}
                  formatter={vnCurrency}
                />
                <RateText
                  text="Rate Empire"
                  rate={setting.result?.rate.empire}
                  formatter={vnCurrency}
                />
                <RateText
                  text="Rate CSGO500"
                  rate={setting.result.rate.csgo500}
                  formatter={vnCurrency}
                />
              </div>
              <div className="mt-3 flex-1 lg:m-0">
                <p className="text-sm">
                  Số lượng vật phẩm: &nbsp;
                  <b className="text-secondary">
                    {cartItems.length +
                      cartGifts.length +
                      (cartCoins > 0 ? 1 : 0)}
                  </b>
                </p>
                <p className="text-sm">
                  Tổng tiền thanh toán: &nbsp;
                  <b className="text-secondary">
                    {vnCurrency.format(amount + totalCartGiftsPrice)}
                  </b>
                </p>
              </div>
            </>
          ) : null}
        </div>
        {!allowOrder ? (
          <p className="mb-3 text-center text-sm text-red-500">
            Bạn cần trên {vnCurrency.format(min)} để có thể thanh toán
          </p>
        ) : null}
        <button
          className={clsx(
            'mx-auto block px-6 py-2 font-medium text-white',
            'rounded-sm border-2 border-green-500 bg-green-500',
            {
              'hover:bg-transparent': allowOrder,
              'border-gray-400 bg-gray-300 bg-opacity-50': !allowOrder,
            }
          )}
          disabled={!allowOrder}
          onClick={handleClickOrder}
        >
          <span>Đặt hàng</span>
        </button>
      </>
    )
  }

  if (orderMutation.isSuccess && createdOrder) {
    return (
      <>
        <div className="text-center">
          <p className="font-medium text-green-500">
            Chọn một phương thức dưới đây để thanh toán.
          </p>
          <p className="mt-3 text-lg font-bold text-red-500">
            Thời gian còn lại: {Math.floor(timeLeft / 60)}:
            {(timeLeft % 60).toString().padStart(2, '0')}
          </p>
          <p className="mt-1 text-sm text-red-500">
            *Đơn hàng của bạn sẽ bị hủy nếu không thanh toán trong thời gian
            trên
          </p>
        </div>
        <PaymentMethod order={createdOrder.result} />
        <div>
          <p className="mb-5 text-center font-semibold text-secondary">
            Vật phẩm đã đặt
          </p>
          <ListGameItem
            className="grid grid-cols-3 gap-x-2 gap-y-4 lg:grid-cols-5"
            items={createdOrder.result.items}
            clickable={false}
          />
          <ListGift
            className={clsx('grid grid-cols-3 gap-x-2 gap-y-4 lg:grid-cols-5', {
              'mt-3 border-t border-secondary pt-3':
                createdOrder.result.items.length > 0 &&
                createdOrder.result.gifts.length > 0,
            })}
            gifts={createdOrder.result.gifts}
            clickable={false}
          />
          <div
            className={clsx({
              'mt-3 border-t border-secondary pt-3':
                (createdOrder.result.items.length > 0 ||
                  createdOrder.result.gifts.length > 0) &&
                createdOrder.result.coins > 0,
            })}
          >
            <CoinsCard
              coins={parseFloat(
                (createdOrder.result.coins * EMPIRE_EXCHANGE).toFixed(tofix_2)
              )}
              clickable={false}
            />
          </div>
          <div
            className={clsx({
              'mt-3 border-t border-secondary pt-3':
                (createdOrder.result.items.length > 0 ||
                  createdOrder.result.gifts.length > 0 ||
                  createdOrder.result.coins > 0) &&
                createdOrder.result.csgo500.userId,
            })}
          >
            <CSGO500Card
              userId={createdOrder.result.csgo500.userId ?? null}
              coins={parseFloat(
                (
                  createdOrder.result.csgo500?.amount * CSGO500_EXCHANGE
                ).toFixed(tofix_3)
              )}
              clickable={false}
            />
          </div>
        </div>
      </>
    )
  }

  return null
}

export default memo(PaymentSection)
