import {useQuery} from '@tanstack/react-query'
import type {APIError} from 'lib/api-client'
import type {
  BankAccount,
  Setting,
  SettingInfo,
  sellOrderInfo,
  BankList,
} from './types'
import {
  getBankAccounts,
  getSetting,
  getSettingInfo,
  getSellOrderSettingInfo,
  getBankList,
} from './api'
import settingKeys from './keys'
import {BaseResponse} from 'data/orders'

export function useSetting() {
  return useQuery<BaseResponse<Setting>, APIError>(
    settingKeys.rate(),
    getSetting,
    {
      staleTime: Infinity,
    }
  )
}

export function useBankAccounts() {
  return useQuery<BaseResponse<BankAccount[]>, APIError>(
    settingKeys.banks(),
    getBankAccounts,
    {
      refetchOnWindowFocus: false,
    }
  )
}

export function useBankList() {
  return useQuery<BaseResponse<BankList[]>, APIError>(
    settingKeys.banksList(),
    getBankList,
    {
      refetchOnWindowFocus: false,
    }
  )
}

export function useSettingInfo() {
  return useQuery<BaseResponse<SettingInfo>, APIError>(
    settingKeys.info(),
    getSettingInfo
  )
}

export function useSellOrderSetting() {
  return useQuery<BaseResponse<sellOrderInfo>, APIError>(
    settingKeys.sellOrder(),
    getSellOrderSettingInfo
  )
}
