import {memo} from 'react'
import {GlassIcon} from 'components/icons'

type FilterItemsProps = {
  search: string
  sort: number
  onSearchChange: (text?: string) => any
  onSortChange: (value: number) => any
}

function FilterItems({
  search,
  sort,
  onSearchChange,
  onSortChange,
}: FilterItemsProps) {
  return (
    <div className="flex flex-col justify-center md:flex-row">
      <div className="group flex w-full items-center rounded-full border-2 border-secondary bg-black px-3 lg:max-w-md">
        <GlassIcon className="fill-secondary" width={20} height={20} />
        <input
          className="ml-1 flex-1 rounded-full bg-transparent px-2 py-2 text-sm placeholder:text-[#ddd] focus-visible:outline-none md:text-base"
          type="text"
          placeholder="Tên vật phẩm"
          value={search}
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </div>
      <div className="mt-3 rounded-md border-2 border-secondary bg-black px-2 md:mt-0 md:ml-3 md:w-48">
        <select
          className="w-full rounded-md bg-transparent py-2 text-center text-sm focus-visible:outline-none md:text-base"
          value={sort}
          onChange={(e) => onSortChange(+e.target.value)}
        >
          <option className="bg-black" value={0}>
            Sắp xếp theo
          </option>
          <option className="bg-black" value={1}>
            Value tăng dần
          </option>
          <option className="bg-black" value={2}>
            Value giảm dần
          </option>
        </select>
      </div>
    </div>
  )
}

export default memo(FilterItems)
