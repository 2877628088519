import client from 'lib/api-client'
import type {
  BankAccount,
  Setting,
  SettingInfo,
  sellOrderInfo,
  BankList,
} from './types'
import {BaseResponse} from 'data/orders'

const endpoint = '/api/settings'

export function getSetting() {
  return client.get<BaseResponse<Setting>>(endpoint)
}

export function getBankAccounts() {
  return client.get<BaseResponse<BankAccount[]>>(endpoint + '/accounts')
}

export function getBankList() {
  return client.get<BaseResponse<BankList[]>>(endpoint + '/banks')
}

export function getSettingInfo() {
  return client.get<BaseResponse<SettingInfo>>(endpoint + '/linkrate')
}

export function getSellOrderSettingInfo() {
  return client.get<BaseResponse<sellOrderInfo>>(endpoint + '/sellorder')
}
