import {memo, useEffect, useState} from 'react'
import clsx from 'clsx'
import {useBankAccounts} from 'data/settings'
import type {BankAccount} from 'data/settings'
import {CarretDownIcon} from 'components/icons'
import Spinner from 'components/spinner'

type BankSelectProps = {
  selected: BankAccount | null
  amount: number
  onSelect: (bankAccount: BankAccount) => any
}

const limitMomo = 500000
function BankSelect({selected, onSelect, amount}: BankSelectProps) {
  const [openSelect, setOpenSelect] = useState(false)
  const {isFetching, data: banks} = useBankAccounts()

  useEffect(() => {
    if (openSelect) {
      const timer = setTimeout(() => {
        setOpenSelect(false)
      }, 2500)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [openSelect])

  if (isFetching) {
    return <Spinner center />
  }

  if (!banks || banks.result.length <= 0) {
    return null
  }

  return (
    <div
      className="mx-auto max-w-[325px] rounded-md border border-secondary bg-black hover:cursor-pointer"
      onClick={() => setOpenSelect((prev) => !prev)}
    >
      {!selected ? (
        <div className="flex h-[40px] items-center justify-center py-[6px]">
          <p className="mr-2 text-sm font-medium text-secondary">
            Chọn một ngân hàng
          </p>
          <CarretDownIcon width={14} height={14} />
        </div>
      ) : (
        <BankOption bankAcc={selected} />
      )}
      {openSelect ? (
        <div className="absolute mt-2 w-full max-w-[325px] border-x border-b border-secondary bg-black">
          {banks.result.map((bankAcc) => {
            // if (bankAcc.bin == 970415) return
            if (
              (bankAcc.bin > 200000000 || bankAcc.bin == -1) &&
              amount >= limitMomo
            ) {
              return
            }
            return (
              <BankOption
                key={bankAcc.shortName}
                bankAcc={bankAcc}
                setSelectedBank={onSelect}
              />
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

function BankOption({
  bankAcc,
  setSelectedBank,
}: {
  bankAcc: BankAccount
  setSelectedBank?: (bankAccount: BankAccount) => void
}) {
  return (
    <div
      className={clsx('relative flex items-center py-[6px]', {
        'border-t border-secondary hover:cursor-pointer hover:border-r-8':
          !!setSelectedBank,
      })}
      key={bankAcc.shortName}
      onClick={() => (setSelectedBank ? setSelectedBank(bankAcc) : {})}
    >
      <div className="mr-1 max-w-[45px]">
        <img
          className="max-h-full max-w-full"
          src={bankAcc.logo}
          alt={bankAcc.shortName}
        />
      </div>
      <div>
        <div className="text-sm font-medium text-secondary">
          {bankAcc.shortName}
        </div>
        <div className="mt-[1px] text-xs text-neutral-500">{bankAcc.name}</div>
      </div>
      {!setSelectedBank ? (
        <CarretDownIcon
          className="absolute top-1/3 right-3"
          width={14}
          height={14}
        />
      ) : null}
    </div>
  )
}

export default memo(BankSelect)
