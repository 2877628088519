import {atom} from 'jotai'
import {atomWithStore} from 'jotai/zustand'
import create from 'zustand/vanilla'

type Toast = {
  variant: 'success' | 'info' | 'warning' | 'danger'
  title: string
  message: string | JSX.Element
}

type ToastStore = {
  toasts: Toast[]
  showToast: (toast: Toast) => void
}

const toastStore = create<ToastStore>((set) => ({
  toasts: [],
  showToast: (toast) => set((state) => ({toasts: [toast, ...state.toasts]})),
}))

const toastStoreAtom = atomWithStore<ToastStore>(toastStore)
toastStoreAtom.debugLabel = 'toasts'

const toastsAtom = atom((get) => get(toastStoreAtom).toasts)

const showToastAtom = atom(null, (get, set, toast: Toast) =>
  set(toastStoreAtom, {
    ...get(toastStoreAtom),
    toasts: [toast, ...get(toastsAtom)],
  })
)

const removeToastAtom = atom(null, (get, set, index: number) =>
  set(toastStoreAtom, {
    ...get(toastStoreAtom),
    toasts: get(toastsAtom).filter((t, idx) => idx !== index),
  })
)

export {toastStore, toastsAtom, showToastAtom, removeToastAtom}
export type {Toast}
