import {HubConnection, HubConnectionBuilder} from '@microsoft/signalr'

class SignalRConnection {
  private static instance: HubConnection | null = null

  public static async getConnection(): Promise<HubConnection> {
    if (!this.instance) {
      this.instance = new HubConnectionBuilder()
        .withUrl(`/api/signal`, {withCredentials: true})
        .withAutomaticReconnect()
        .build()
      await this.instance.start()
      console.log('SignalR Connected')
    }
    return this.instance
  }

  public static async stopConnection(): Promise<void> {
    if (this.instance) {
      await this.instance.stop()
      this.instance = null
      console.log('SignalR Disconnected')
    }
  }
}

export default SignalRConnection
