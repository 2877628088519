type CloseIconProps = {} & JSX.IntrinsicElements['svg']

function CloseIcon(props: CloseIconProps) {
  return (
    <svg
      fill="#fff"
      width={30}
      height={30}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.000000 512.000000"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M576 5109 c-106 -17 -170 -60 -338 -229 -128 -127 -163 -168 -187
-219 -63 -133 -62 -269 0 -403 28 -57 101 -134 847 -880 l816 -818 -815 -817
c-748 -750 -819 -823 -847 -883 -63 -132 -63 -269 -1 -401 25 -52 59 -92 187
-220 175 -174 229 -208 359 -230 60 -10 83 -9 142 4 156 36 110 -4 1004 886
l817 815 808 -806 c444 -443 825 -817 847 -830 165 -102 364 -95 513 17 31 24
114 101 183 172 96 98 133 145 157 194 64 131 64 266 0 399 -28 60 -99 133
-847 883 l-815 817 815 818 c749 749 819 822 847 882 64 133 64 268 0 400 -25
53 -60 94 -187 221 -127 128 -168 162 -221 188 -129 62 -271 62 -400 0 -59
-29 -135 -102 -882 -848 l-818 -816 -817 816 c-739 736 -824 818 -883 847 -94
46 -180 59 -284 41z"
        />
      </g>{' '}
    </svg>
  )
}

export default CloseIcon
