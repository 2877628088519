import clsx from 'clsx'

type SpinnerProps = {
  size?: 'sm' | 'md' | 'lg'
  center?: boolean
} & JSX.IntrinsicElements['div']

function Spinner({size = 'md', center, className, ...props}: SpinnerProps) {
  return (
    <div
      className={clsx(
        'w-12 text-center text-xs',
        {
          'h-12': size === 'sm',
          'h-16': size === 'md',
          'h-20': size === 'lg',
          'absolute top-1/2 left-1/2 z-[997] -translate-x-1/2 -translate-y-1/2':
            center,
        },
        className
      )}
      {...props}
    >
      <Rect />
      <Rect className="animation-delay-[-1.1s]" />
      <Rect className="animation-delay-[-1.0s]" />
      <Rect className="animation-delay-[-0.9s]" />
      <Rect className="animation-delay-[-0.8s]" />
    </div>
  )
}

function Rect({className}: {className?: string}) {
  return (
    <div
      className={clsx(
        'mr-1 inline-block h-full w-1 animate-[stretch_1.2s_infinite_ease-in-out] bg-[#ddd]',
        className
      )}
    ></div>
  )
}

export default Spinner
