import {useEffect} from 'react'
import {useRouter} from 'next/router'
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {useUpdateAtom} from 'jotai/utils'
import {emptyCartAtom} from 'atoms/cart'
import type {APIError} from 'lib/api-client'
import type {GetAuthState, GetClaims} from './types'
import {getAuthState, getClaims} from './api'
import authKeys from './keys'
import {BaseResponse} from 'data/orders'

type UseAuthOptions = {
  authenticatedRedirect?: string
  unauthenticatedRedirect?: string
}

export function useAuth(
  options: UseAuthOptions = {
    authenticatedRedirect: '',
    unauthenticatedRedirect: '',
  }
) {
  const {authenticatedRedirect, unauthenticatedRedirect} = options
  const router = useRouter()
  const emptyCart = useUpdateAtom(emptyCartAtom)
  const currentUser = useQuery<BaseResponse<GetAuthState>, APIError>(
    authKeys.currentUser,
    getAuthState,
    {
      staleTime: Infinity,
      onSuccess: (data) => {
        if (!data.result?.authenticated) {
          emptyCart()
        }
        return
      },
      onError: () => {
        emptyCart()
        return
      },
    }
  )

  useEffect(() => {
    if (currentUser.data?.result) {
      const user = currentUser.data.result.user
      if (authenticatedRedirect && user) {
        router.push(authenticatedRedirect)
      }
      if (unauthenticatedRedirect && !user) {
        router.push(unauthenticatedRedirect)
      }
    }
  }, [authenticatedRedirect, unauthenticatedRedirect, router, currentUser.data])

  return currentUser
}

export function useGetClaims() {
  return useQuery<BaseResponse<GetClaims>, APIError>(
    authKeys.currentClaims,
    getClaims
  )
}
