import {memo} from 'react'
import {GameItem} from 'data/game-items'
import GameItemCard from './game-item-card'

type ListGameItemProps = {
  items: GameItem[]
  clickable?: boolean
} & JSX.IntrinsicElements['div']

function ListGameItem({items, clickable = true, ...props}: ListGameItemProps) {
  return (
    <div {...props}>
      {items.map((item) => (
        <GameItemCard key={item.id} item={item} clickable={clickable} />
      ))}
    </div>
  )
}

export default memo(ListGameItem)
