import {authKeys} from 'data/auth'
import queryClient from './query-client'

type APIError = {
  statusCode: number
  error: string
  message: string
}

type RequestConfig = {
  query?: any
  body?: any
} & RequestInit

async function request<T>(
  endpoint: string,
  {body, query, ...customConfig}: RequestConfig = {}
) {
  const headers = {'Content-Type': 'application/json'}
  const config: RequestInit = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }

  if (body) {
    config.body = JSON.stringify(body)
  }

  let res: Response

  if (query) {
    res = await fetch(`${endpoint}?${new URLSearchParams(query)}`)
  } else {
    res = await fetch(endpoint, config)
  }

  const data: T | APIError = await res.json()

  if (!res.ok) {
    if (res.status === 401) {
      await queryClient.invalidateQueries(authKeys.currentUser)
    }

    return Promise.reject(data)
  }

  return data as T
}

type ClientConfig = Omit<RequestConfig, 'method'>

const client = {
  get<T = any>(endpoint: string, config: ClientConfig = {}) {
    return request<T>(endpoint, {...config, method: 'GET'})
  },
  post<T = any>(endpoint: string, config: ClientConfig = {}) {
    return request<T>(endpoint, {...config, method: 'POST'})
  },
  put<T = any>(endpoint: string, config: ClientConfig = {}) {
    return request<T>(endpoint, {...config, method: 'PUT'})
  },
  patch<T = any>(endpoint: string, config: ClientConfig = {}) {
    return request<T>(endpoint, {...config, method: 'PATCH'})
  },
  delete<T = any>(endpoint: string, config: ClientConfig = {}) {
    return request<T>(endpoint, {...config, method: 'DELETE'})
  },
}

export type {APIError}
export default client
