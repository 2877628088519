/* eslint-disable @next/next/inline-script-id */
import Script from 'next/script'

function FacebookChat() {
  return (
    <>
      <div id="fb-root"></div>
      <div id="fb-customer-chat" className="fb-customerchat"></div>
      <Script strategy="lazyOnload">
        {`
            var chatbox = document.getElementById('fb-customer-chat')
            if (chatbox) {
              chatbox.setAttribute('page_id', '341867329270473')
              chatbox.setAttribute('attribution', 'biz_inbox')
        
              window.fbAsyncInit = function () {
                FB.init({
                  xfbml: true,
                  version: 'v14.0',
                })
              }
              ;(function (d, s, id) {
                var js,
                  fjs = d.getElementsByTagName(s)[0]
                if (d.getElementById(id)) return
                js = d.createElement(s)
                js.id = id
                js.src = 'https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js'
                fjs.parentNode.insertBefore(js, fjs)
              })(document, 'script', 'facebook-jssdk')
            }
        `}
      </Script>
    </>
  )
}

export default FacebookChat
