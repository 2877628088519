import {useAtomValue} from 'jotai'
import {
  cart500CoinsAtom,
  cartCoinsAtom,
  cartGiftsAtom,
  cartItemsAtom,
} from 'atoms/cart'
import {CartIcon} from '../icons'
import Link from 'next/link'

function CartButton(): JSX.Element {
  const cartItems = useAtomValue(cartItemsAtom)
  const cartGifts = useAtomValue(cartGiftsAtom)
  const cartCoins = useAtomValue(cartCoinsAtom)
  const cart500Coins = useAtomValue(cart500CoinsAtom)

  const coins = cartCoins > 0 ? 1 : 0
  const csgo500Coins = cart500Coins ? 1 : 0

  return (
    <Link href="/mua-hang" passHref>
      <a className="relative mr-4 hover:cursor-pointer md:mr-6 md:inline-block">
        <CartIcon className="fill-secondary" width={23} height={23} />
        {cartItems.length + cartGifts.length + coins + csgo500Coins > 0 ? (
          <div className="absolute -top-2 -right-3 flex h-[17px] w-[17px] items-center justify-center rounded-full border border-white bg-secondary">
            <span className="text-[9px] font-semibold">
              {cartItems.length + cartGifts.length + coins + csgo500Coins}
            </span>
          </div>
        ) : null}
      </a>
    </Link>
  )
}

export default CartButton
